import { useContext, useState, useEffect } from 'react'
import ProductContext from 'components/ProductContext'
import defaultFallback from 'images/wireframe-image.png'

export default ({ product, fallback = defaultFallback }) => {
  const context = useContext(ProductContext)
  const [pictures, setPicture] = useState([])

  const getPicture = async (url) => {
    try {
      const response = await fetch(url)
      if (!response.ok) {
        return fallback
      }
      const blob = await response.blob()
      // const buffer = await response.arrayBuffer()
      // const data = window.btoa(
      //  new Uint8Array(buffer).map(byte => String.fromCharCode(byte))
      // )
      // return `data:image/png;base64;,${data}` */
      // console.log(data, URL.createObjectURL(blob))
      return URL.createObjectURL(blob)
    } catch (e) {
      return fallback
    }
  }

  useEffect(
    () => {
      const url = product?.component?.propertyCombinationUrlPattern?.replace(
        /%[^%]*%/g,
        (prop) => {
          const selected = Object.values(context?.[product.id] || {})
          const properties = product.component.properties
            ?.filter(
              p => `%${p.component?.group?.[0]?.component?.coverKey}%` === prop
            )
            .reduce((acc, value) => acc.concat(value.component?.properties), [])
          return (
            properties?.find(p => selected.indexOf(p.id) > -1)?.component
              ?.slug || properties[0]?.component?.slug
          )
        }
      )
      Promise.all([
        getPicture(
          `/image-cdn/productCoverFilter%2F${url?.replace(
            '/',
            '%2F'
          )}.0001.jpg?alt=media`
        ),
        getPicture(
          `/image-cdn/productCoverFilter%2F${url?.replace(
            '/',
            '%2F'
          )}.0003.jpg?alt=media`
        )
      ]).then(setPicture)
    },
    [product, context]
  )

  return pictures
}
