import React, { useReducer } from 'react'
import styled, { withTheme } from 'styled-components'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import Button from 'components/Button'
import Modal from 'components/Modal'
import useProduct from 'hooks/useProduct'
import useProductContext from 'hooks/useProductContext'
import pinpoint from 'images/Hot_Spot.svg'
import pinpointBlue from 'images/hotSpotBlue.png'
import { mediaQuery } from 'utils/style'

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 61.8%;
`

const PinpointWrapper = styled.div`
  position: relative;
  ${mediaQuery()}
`

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
`

function StoryblokTileProductHighlightPinpoint ({ data, dimensions, theme }) {
  const [open, toggle] = useReducer(state => !state, false)
  const product = useProduct(data.product[0])
  const highlights = product?.component?.highlights?.filter(hl => hl?.primary)
  const { select, selected } = useProductContext(product, 'highlight')

  return (
    <Wrapper>
      <Info>
        <div className="title">{product?.component?.description1Title}</div>
        {product?.component?.description1?.childMarkdownRemark?.htmlAst && (
          <RemarkRenderer
            flexFill
            clamp="auto"
            ast={product.component.description1.childMarkdownRemark.htmlAst}
            style={{ padding: '5px 0' }}
          />
        )}
        <Modal product={product} open={open} onRequestClose={toggle} />
        <Button onClick={toggle}>{data.buttonLabel}</Button>
      </Info>
      <ImageWrapper>
        <PinpointWrapper
          media={(device) => {
            const ratio = dimensions(device).ratio * 0.618
            const metaRatio =
              product?.component?.image?.localFile?.childImageSharp?.fluid
                ?.aspectRatio / ratio

            if (metaRatio > 1) {
              return `width: 100%; height: ${100 / metaRatio}%;`
            }
            return `height: 100%; width: ${100 * (metaRatio || 1)}%;`
          }}
        >
          <Image
            absolute
            src={product?.component?.image}
            alt={product?.component?.imageAlt}
            title={product?.component?.imageTitle}
            description={product?.component?.imageDescription}
          />
          {highlights?.map(hl => (
            <Image
              hover="filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);"
              key={hl.id}
              src={theme.tertiary === 'blue' ? pinpointBlue : pinpoint}
              style={{
                position: 'absolute',
                height: `${hl.size}rem`,
                width: `${hl.size}rem`,
                top: `calc(${hl.top}% - ${hl.size / 2}rem)`,
                left: `calc(${hl.left}% - ${hl.size / 2}rem)`,
                ...((selected || highlights[0].highlight[0].id)
                  === hl.highlight[0].id && {
                  filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 2px 2px 5px)'
                })
              }}
              onClick={() => select(hl.highlight[0].id)}
            />
          ))}
        </PinpointWrapper>
      </ImageWrapper>
    </Wrapper>
  )
}
export default withTheme(StoryblokTileProductHighlightPinpoint)
