import React, { useReducer } from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import Button from 'components/Button'
import Modal from 'components/Modal'
import useProduct from 'hooks/useProduct'
import useProductContext from 'hooks/useProductContext'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 0;
  width: 100%;
`

const Option = styled.div`
  margin: 0 5px;
  flex: 1;
  position: relative;
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1.5rem;
  margin-bottom: 16px;
  text-align: center;
  ::before {
    bottom: 0;
    position: absolute;
    content: " ";
    border-radius: 1rem;
    border 2px solid;
    width: 1rem;
    height: 1rem;
    ${props =>
    (props.selected
      ? `background-color: ${props.theme.colors.blue.color};`
      : '')}
  }
`

export default function storyblokTileHighlightCarousel ({ data }) {
  const [open, toggle] = useReducer(state => !state, false)
  const product = useProduct(data.product[0])
  const highlights = product?.component?.highlights?.filter(hl => hl.primary)

  const { selected, select } = useProductContext(product, 'highlight')

  const highlight = selected
    ? highlights?.find(hl => hl.highlight[0].id === selected)
    : highlights?.[0]

  return (
    <Wrapper>
      <div className="title" style={{ alignSelf: 'start' }}>
        {product?.component?.highlightsTitle}
      </div>
      <Image
        flex
        row
        key={highlight?.highlight?.[0].id}
        src={highlight?.highlight?.[0]?.component?.image}
        style={{
          overflow: 'hidden',
          marginTop: highlight?.highlight?.[0]?.component?.removeMargin
            ? '-56px'
            : ''
        }}
        alt={highlight?.highlight?.[0]?.component?.imageAlt}
        title={highlight?.highlight?.[0]?.component?.imageTitle}
        description={highlight?.highlight?.[0]?.component?.imageDescription}
      />
      <Options>
        {highlights?.map(hl => (
          <Option
            key={hl.id}
            onClick={() => select(hl.highlight?.[0]?.id)}
            selected={hl === highlight}
          >
            {hl.highlight?.[0]?.component?.imageTitle}
          </Option>
        ))}
      </Options>
      <Modal
        product={product}
        type="highlight"
        open={open}
        onRequestClose={toggle}
      />
      <Button style={{ alignSelf: 'start' }} onClick={toggle}>
        {data.buttonLabel}
      </Button>
    </Wrapper>
  )
}
