import React from 'react'
import styled from 'styled-components'
import useProduct from 'hooks/useProduct'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    margin-bottom: 0;
  }
`

export default function storyblokTileProductTitle({ data }) {
  const product = useProduct(data.product[0])
  return (
    <Container>
      <div className="roofline">{product?.component?.Roofline}</div>
      <h1>{product?.component?.title}</h1>
      <div className="subline">{product?.component?.subTitle}</div>
    </Container>
  )
}
