import React, { useContext } from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import Button from 'components/Button'
import Link from 'components/Link'
import ProductPropertyCarousel from 'components/ProductPropertyCarousel'
import useProduct from 'hooks/useProduct'
import useProductImageUrl from 'hooks/useProductImageUrl'
import { mediaQuery } from 'utils/style'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
`

const Properties = styled.div`
  overflow: hidden;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${mediaQuery()}
`

/* const Preview=styled.div`
flex:2;
flex-direction:column;
display:flex;
justify-content:space-between;
align-items:flex-start;
`
const Row=styled.div`
  display:flex;
  flex-direction: row;
  justify-content:space-between;

` */

export default function storyblokTileProductCoverFilter ({ data, dimensions }) {
  const product = useProduct(data.product[0])
  const properties = Object.values(
    product?.component?.properties?.reduce(
      (acc, value) => ({
        ...acc,
        [value?.component?.group?.[0]?.id]: acc?.[
          value?.component?.group?.[0]?.id
        ]?.component?.priority
          ? acc[(value?.component?.group?.[0]?.id)]
          : value
      }),
      {}
    ) || {}
  )
  const [pic1, pic2] = useProductImageUrl({
    product,
    fallback: data.fallbackImage
  })

  return (
    <Wrapper>
      <div className="title">{data.title}</div>
      <Properties
        media={device =>
          (dimensions(device).ratio > 1
            ? 'flex-direction: row;'
            : 'flex-direction: column;')
        }
      >
        {/* <Preview>
          <Row media={(dimensions(device).ratio > 1
            ? 'flex-direction: row;'
            : 'flex-direction: column;')}> */}
        <Image
          style={{ padding: '22px 5px 5px' }}
          imageStyle={{ objectPosition: 'bottom !important' }}
          flex
          row
          src={pic1}
        />
        <Image
          style={{ padding: '22px 5px 5px' }}
          imageStyle={{ objectPosition: 'bottom !important' }}
          flex
          row
          src={pic2}
        />

        {/*   </Row><Link media={device=>} link={data.buttonLink}>
             <Button>{data.buttonLabel}</Button>
         </Link>
        </Preview> */}
        {properties?.map(prop => (
          <ProductPropertyCarousel
            key={prop.id}
            product={product.id}
            property={prop}
          />
        ))}
      </Properties>
      <Link link={data.buttonLink}>
        <Button>{data.buttonLabel}</Button>
      </Link>
    </Wrapper>
  )
}
