import React from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import Button from 'components/Button'
import Link from 'components/Link'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
`
const Content = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 96px;
  ${mediaQuery()}
`

const ContentImage = styled.div`
  position: relative;
  flex: 1;
  ${mediaQuery()}
  margin-bottom:16px;
`
const SideImage = styled.div`
  ${mediaQuery()}
`

export default function StoryblokTileCallToAction ({
  data,
  dimensions,
  linkProps
}) {
  return (
    <Wrapper>
      <Content
        media={device =>
          (dimensions(device).ratio > 1 ? 'width:50%;' : 'width:100%;')
        }
      >
        <div className="title">{data.title}</div>
        <ContentImage
          media={device =>
            (dimensions(device).ratio > 1 ? 'display:none;' : 'display:block;')
          }
        >
          <Image
            style={{ position: 'absolute' }}
            src={data.image}
            alt={data.imageAlt}
            title={data.imageTitle}
            description={data.imageDescription}
          />
        </ContentImage>
        <RemarkRenderer
          flexFill
          style={{ padding: '5px 0' }}
          clamp="auto"
          ast={data.text.childMarkdownRemark?.htmlAst}
        />
        {data.buttonLabel && (
          <Link
            {...linkProps || {
              link: data.link,
              gtmEvent: data.gtmEvent,
              productModalType: data.extendedProductModalLink,
              productModalGroup: data.extendedProductModalGroup
            }}
          >
            <Button>{data.buttonLabel}</Button>
          </Link>
        )}
      </Content>

      <SideImage
        media={device =>
          (dimensions(device).ratio > 1
          && ((typeof data.image === 'string' && data.image.length > 0)
            || data.image?.localFile)
            ? 'display:block'
            : 'display:none;')
        }
        style={{
          width: '50%',
          margin: data.extendedRemoveMargins
            ? '-16px -16px -16px 0'
            : '0 0 0 16px'
        }}
      >
        <Image
          src={data.image}
          imageStyle={{
            objectPosition: `${data.extendedAlignmentHorizontal} ${
              data.extendedAlignmentVertical
            } !important`
          }}
          alt={data.imageAlt}
          title={data.imageTitle}
          description={data.imageDescription}
        />
      </SideImage>
    </Wrapper>
  )
}
