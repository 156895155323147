import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer
} from 'react'
import styled from 'styled-components'
import { baseColors } from 'utils/theme'
import { mediaQuery } from 'utils/style'
import { ModalContext } from 'components/Modal'
import StoryblokComponents from 'components/StoryblokComponents'
import Image from 'components/Image'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import Close from '@material-ui/icons/Close'

const Anchor = styled.div`
  z-index: 700;
  width: 55px;
  height: 55px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors[theme.primary].color};
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
  border-radius: 50%;
  position: fixed;
  ${mediaQuery()}
`

const CloseButton = styled(Close)`
  && {
    font-size: 32px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 1500;
    ${mediaQuery()({
    media: device => (device === 'mobile' ? 'top: 8px; left: 8px;' : '')
  })}
  }
`

const Popup = styled(Popper)`
  > * {
    &:nth-child(n + 3) {
      margin-top: 16px;
    }
  }
  position: fixed;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  visibility: hidden;
  background-color: white;
  padding: 12px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  z-index: 701;
  ${mediaQuery()}
  overflow: visible !important;
`

const Container = styled.div`
  > * {
    &:nth-child(n + 3) {
      margin-top: 16px;
    }
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${({ open }) => (open ? 'visibility: visible;' : '')}
  background-color: white;
  padding: 12px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  z-index: 701;
  ${mediaQuery()}
`

export const PopperContext = React.createContext()

export default function StoryblokPopup ({ data }) {
  const [open, setOpen] = useState(false)
  const [panelsOpen, setPanelOpen] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  )
  const popper = useRef()
  const anchorEl = useRef()
  useEffect(
    () => {
      const timeout = setTimeout(() => setOpen(true), data.timeout)
      return () => clearTimeout(timeout)
    },
    [data.timeout]
  )
  const onRequestClose = useCallback(() => setOpen(false))
  const anchorMedia = useCallback(device => data[`anchorCSS${device}`], [data])
  const bodyMedia = useCallback(device => data[`bodyCSS${device}`], [data])

  if (!data.enabled) return null

  return (
    <>
      <Anchor
        media={anchorMedia}
        ref={anchorEl}
        onClick={() => setOpen(!open)}
        onMouseEnter={() => setOpen(true)}
      >
        <Image src={data.icon} />
      </Anchor>
      <Popup
        open={open}
        anchorEl={anchorEl.current}
        placement={data.placement}
        popperOptions={{ positionFixed: true }}
        keepMounted
        modifiers={{
          computeStyle: {
            enabled: true,
            x: data.growX,
            y: data.growY
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          },
          arrow: {
            enabled: true
          }
        }}
        popperRef={popper}
        media={bodyMedia}
      >
        <ModalContext.Provider value={{ onRequestClose }}>
          <PopperContext.Provider value={{ popper, panelsOpen, setPanelOpen }}>
            <Container media={bodyMedia} open={open}>
              <CloseButton onClick={onRequestClose} />
              <StoryblokComponents
                components={data.body}
                onLoaded={popper.scheduleUpdate}
              />
            </Container>
          </PopperContext.Provider>
          <PopperContext.Provider value={{ panelsOpen }}>
            <StoryblokComponents
              components={data.body}
              onLoaded={popper.scheduleUpdate}
            />
          </PopperContext.Provider>
        </ModalContext.Provider>
      </Popup>
    </>
  )
}
