import { useEffect, useState, useRef } from 'react'
import { equals } from 'ramda'
import remark from 'remark'
import toHAST from 'mdast-util-to-hast'
import removePosition from 'unist-util-remove-position'
import handleRaw from 'hast-util-raw'
import youtube from 'gatsby-remark-embed-youtube'

export function processRemark (content) {
  return handleRaw(
    removePosition(
      toHAST(youtube({ markdownAST: remark.parse(content) }), {
        allowDangerousHTML: true
      })
    )
  )
}

function useRemark (content) {
  const initialState = {
    childMarkdownRemark: { htmlAst: { type: 'root', children: [] } }
  }
  const [state, setState] = useState(
    Array.isArray(content) ? [initialState] : initialState
  )

  const contentRef = useRef(null)
  useEffect(
    () => {
      if (!equals(contentRef.current, content)) {
        contentRef.current = content
        if (Array.isArray(content)) {
          setState(
            content.map((text) => {
              const htmlAst = processRemark(text)
              return { childMarkdownRemark: { htmlAst } }
            })
          )
        } else {
          const htmlAst = processRemark(content)
          setState({ childMarkdownRemark: { htmlAst } })
        }
      }
    },
    [content]
  )
  return state
}

export default useRemark
